import { TeamRole, UserRole } from "@prisma/client";
import { duration } from "moment";
import { start } from "repl";
import * as z from "zod";
import validator from "validator";
// import { UserRole } from "@prisma/client";

export const SettingsSchema = z.object({
  name: z.optional(z.string()),
  isTwoFactorEnabled: z.optional(z.boolean()),
  role: z.enum([UserRole.ADMIN, UserRole.USER, UserRole.BROKER, UserRole.SALESPERSON]),
  email: z.optional(z.string().email()),
  password: z.optional(z.string().min(6)),
  newPassword: z.optional(z.string().min(6)),
})
  .refine((data) => {
    if (data.password && !data.newPassword) {
      return false;
    }

    return true;
  }, {
    message: "New password is required!",
    path: ["newPassword"]
  })
  .refine((data) => {
    if (data.newPassword && !data.password) {
      return false;
    }

    return true;
  }, {
    message: "Password is required!",
    path: ["password"]
  })

export const NewPasswordSchema = z.object({
  password: z.string().min(6, {
    message: "Minimum of 6 characters required",
  }),
});

export const ResetSchema = z.object({
  email: z.string().email({
    message: "Email is required",
  }),
});

export const LoginSchema = z.object({
  email: z.string().email({
    message: "Email is required",
  }),
  password: z.string().min(1, {
    message: "Password is required",
  }),
//   code: z.optional(z.string()),
});

// Register Schema
export const RegisterSchema = z.object({
    firstName: z.string().min(2, {
        message: "Must be at least 2 characters.",
    }).max(50, {
        message: "Must be at most 50 characters.",
    }),
    lastName: z.string().min(2, {
        message: "Must be at least 2 characters.",
    }).max(50, {
        message: "Must be at most 50 characters.",
    }),
    email: z.string().email({
        message: "Invalid email address.",
    }).max(100, {
        message: "Must be at most 100 characters.",
    }),
    password: z.string().min(6, {
        message: "Minimum 6 characters required",
    }).max(50, {
        message: "Must be at most 50 characters.",
    }),
})

// Permission Template 
export const NewPermissionSchema = z.object({
    name: z.string().min(2, {
        message: "Must be at least 2 characters.",
    }).max(50, {
        message: "Must be at most 50 characters.",
    }),
    pageId: z.string().length(32, {
        message: "UUID - Must be 32 characters long.",
    }),
    type: z.enum(["Module", "Page", "Entity"]),
})

// Permission Instance
export const NewPermissionInstanceSchema = z.object({
  note: z.string().max(400, {
    message: "Must be at most 400 characters.",
  }).optional(),
  startDate: z.date(),
  endDate: z.date().optional(),
  level: z.enum(["VIEW", "WRITE", "FULL"]),
  status: z.enum(["ACTIVE", "INACTIVE"]),
  permissionTemplateId: z.coerce.number().int(),
  userId: z.string()
});

// Subscription Template
export const NewSubscriptionTemplateSchema = z.object({
    name: z.string().min(2, {
        message: "Must be at least 2 characters.",
    }).max(50, {
        message: "Must be at most 50 characters.",
    }),
    note: z.string().max(400, {
        message: "Must be at most 400 characters.",
    }).optional(),
    permissions: z.array(z.number()),
})

// Default Subscription Pricing 
export const AddDefaultPricingSchema = z.object({
    subscriptionTemplateId: z.coerce.number().int(),
    userPrice: z.coerce.number(),
    basePrice: z.coerce.number(),
    duration: z.coerce.number().int().gte(1),
})

// Team 
export const NewTeamSchema = z.object({
    name: z.string().min(2, {
        message: "Must be at least 2 characters.",
    }).max(50, {
        message: "Must be at most 50 characters.",
    }),
    companyName: z.string().min(2, {
        message: "Must be at least 2 characters.",
    }).max(50, {
        message: "Must be at most 50 characters.",
    }),
    subscriptionDuration: z.coerce.number().int().gte(1),
})

export const TeamSubscriptionPriceModelSchema = z.object({
    id: z.coerce.number(),
    userPrice: z.coerce.number(),
    basePrice: z.coerce.number(),
    subscriptionTemplateId: z.coerce.number(),
  });

  
export const TeamSubscriptionPriceModelArraySchema = z.array(TeamSubscriptionPriceModelSchema);

export const NewTeamWithSubscriptionSchema = z.object({
    name: z.string().min(2, {
        message: "Must be at least 2 characters.",
    }).max(50, {
        message: "Must be at most 50 characters.",
    }),
    subscriptionDuration: z.coerce.number().int().gte(1),
    subscriptionPrices: TeamSubscriptionPriceModelArraySchema,
})

export const AddUserToSubscriptionSchema = z.object({
    subscriptionInstanceId: z.coerce.number().int(),
})

export const AddSalesResponsibleToClientSchema = z.object({
    userId: z.string(),
    salesPersonId: z.string().optional(),
})

export const UpdateSubscriptionStatusSchema = z.object({
    subscriptionId: z.coerce.number().int(),
    status: z.enum(["ACTIVE", "INACTIVE", "EXPIRED", "UPCOMING", "CANCELLED"]).optional(),
})

export const UsersToTeamSchema = z.object({
    teamId: z.coerce.number().int(),
    userIds: z.array(z.string()),
})

export const SubscriptionToTeamSchema = z.object({
    teamId: z.coerce.number().int(),
    subscriptionTemplateId: z.coerce.number().int(),
    startDate: z.date(),
    endDate: z.date(),
    totalPrice: z.coerce.number(),
    status: z.enum(["ACTIVE", "INACTIVE", "EXPIRED"]),
})

export const RemoveUserFromSubscriptionSchema = z.object({
    subscriptionInstanceId: z.coerce.number().int(),
})

export const AddTeamSubscriptionPriceSchema = z.object({
    teamId: z.coerce.number().int(),
    subscriptionTemplateId: z.coerce.number(),
    price: z.coerce.number()
})

export const UserDetailsSchema = z.object({
    userId: z.string(),
    phone: z.string().max(20, {
        message: "Must be at most 20 characters.",
    }).min(1, {
        message: "Please provide a phone number.",
    }).min(6, { 
        message: "Must be at least 6 characters.",
    }).refine(validator.isMobilePhone, {
        message: "Please pvodide a valid phone number.",
    }),
    company: z.string().max(50, {
        message: "Must be at most 50 characters.",
    }).min(4, {
        message: "Must be at least 4 characters.",
    }),
    countryName: z.string().min(1, {
        message: "Please provide a country.",
    }),
      
})

// Estimated Revenue
export const UpdateEstimatedRevenueSchema = z.object({
    userId: z.string(),
    estimatedRevenue: z.coerce.number().int(),
})

// Market Report Template
export const NewMarketReportTemplateSchema = z.object({
    name: z.string().min(2, {
        message: "Must be at least 2 characters.",
    }).max(100, {
        message: "Must be at most 50 characters.",
    }),
    description: z.string().max(400, {
        message: "Must be at most 400 characters.",
    }).optional(),
    legacyListId: z.string().min(2, {
        message: "Must be at least 2 characters.",
    }).max(100, {
        message: "Must be at most 50 characters.",
    }),
})

const optionSchema = z.object({
    label: z.string(),
    value: z.string(),
    disable: z.boolean().optional(),
  });

export const NewNewsSchema = z.object({
    createdByUserId: z.string(),
    publishedAt: z.date(),
    subject: z.string().max(200, {
        message: "Must be at most 100 characters.",
    }),
    body: z.string().max(4000, {
        message: "Must be at most 4000 characters.",
    }),
    relatedProductOptions: z.array(optionSchema),
    relatedCountryOptions: z.array(optionSchema).min(1, { 
        message: "Please select at least one country."
    }),
    relatedCategoryOptions: z.array(optionSchema).min(1),
    visibility: z.enum(["EXTERNAL", "INTERNAL"]),
})

export const NewMarketEventSchema = z.object({
    createdByUserId: z.string(),
    publishedAt: z.date(),
    timeOfEvent: z.date(),
    subject: z.string().max(200, {
        message: "Must be at most 100 characters.",
    }),
    body: z.string().max(4000, {
        message: "Must be at most 4000 characters.",
    }),
    relatedProductOptions: z.array(optionSchema),
    relatedCountryOptions: z.array(optionSchema).min(1, { 
        message: "Please select at least one country."
    }),
    visibility: z.enum(["EXTERNAL", "INTERNAL"]),
})

export const NewMarketCommentarySchema = z.object({
    createdByUserId: z.string(),
    publishedAt: z.date(),
    body: z.string().max(4000, {
        message: "Must be at most 4000 characters.",
    }),
    relatedProductOptions: z.array(optionSchema),
    relatedCountryOptions: z.array(optionSchema).min(1, { 
        message: "Please select at least one country."
    }),
    visibility: z.enum(["EXTERNAL", "INTERNAL"]),
})

export const AddInvoiceInformationSchema = z.object({
    companyName: z.string().min(2, {
        message: "Must be at least 2 characters.",
    }).max(50, {
        message: "Must be at most 50 characters.",
    }),
    companyVatNumber: z.string().min(2, {
        message: "Must be at least 2 characters.",
    }).max(50, {
        message: "Must be at most 50 characters.",
    }),
    companyAddress: z.string().min(2, {
        message: "Must be at least 2 characters.",
    }).max(50, {
        message: "Must be at most 50 characters.",
    }),
    companyCity: z.string().min(2, {
        message: "Must be at least 2 characters.",
    }).max(50, {
        message: "Must be at most 50 characters.",
    }),
    companyPostalCode: z.string().min(2, {
        message: "Must be at least 2 characters.",
    }).max(50, {
        message: "Must be at most 50 characters.",
    }),
    companyCountryName: z.string()
})

export const AddSubscriptionInstanceSchema = z.object({
    startDate: z.date(),
    endDate: z.date(),
})

// Legacy user
export const AddLegacyUserSchema = z.object({
    email: z.string().email(),
    firstName: z.string().min(2, {
        message: "Must be at least 2 characters.",
    }).max(50, {
        message: "Must be at most 50 characters.",
    }),
    lastName: z.string().min(2, {
        message: "Must be at least 2 characters.",
    }).max(50, {
        message: "Must be at most 50 characters.",
    }),
    teamRole: z.enum([TeamRole.OWNER, TeamRole.ADMIN, TeamRole.MEMBER]),
})

export const UpdateTeamSubscriptionPriceSchema = z.object({
    firstUserPrice: z.coerce.number(),
    additionalUserPrice: z.coerce.number(),
})